






















































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

import Checkbox from '@/components/Input/Checkbox/index.vue';
import Tooltip from '@/components/Tooltip/index.vue';
// import ModalCreditsWarning from '@/components/Modal/ModalCreditsWarning/index.vue';

import HistoricService from '@/services/Historic/historicService';
import ExerciseService from '@/services/Exercise/ExerciseService';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';
import ActiveModal from '@/share/Util/ActiveModal';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { DEFAULT_COPY_MODAL_PLAN_UPDATE } from '@/constant/Modal';

const TYPE_NAME_MATERIALS = 'materiais';
const TYPE_NAME_EXERCICE = 'exercice';
const TYPE_NAME_VIDEO = 'video';

@Component({
  components: {
    Checkbox,
    Tooltip,
    // ModalCreditsWarning,
  },
})
export default class SubjectList extends Vue {
  @Prop() name!: string;
  @Prop() type!: string;
  @Prop() url!: string;
  @Prop() checked!: boolean;
  @Prop({ default: true }) showCheckbox!: boolean;
  @Prop({ default: false }) showLesson!: boolean;
  @Prop({ default: false }) isPermission!: boolean;
  @Prop({ default: true }) isClickable!: boolean;
  @Prop() data!: Record<string, any>;
  @Prop() topic!: Record<string, any>;
  @Prop() teacher!: Array<Record<string, any>>;

  private show = false;

  private setModal = new ActiveModal();

  private historicService = new HistoricService();
  private exerciseService = new ExerciseService();

  beforeDestroy() {
    this.$store.commit('setModalPlanUpdate', DEFAULT_COPY_MODAL_PLAN_UPDATE);
  }

  get isPlanFree() {
    return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get videoClicked() {
    return this.$store.getters.videoClicked;
  }

  setDisplay() {
    if (this.showLesson) {
      return this.showLesson;
    }

    if (!this.$store.getters.teachers.length || !this.teacher) {
      return true;
    }

    let display = false;
    display = this.$store.getters.teachers.some((teacher: Record<string, any>) => {
      if (!teacher.show) return false;

      return this.teacher.some((videoTeacher: Record<string, any>) => videoTeacher.idTeacher === teacher.id);
    });

    return display;
  }

  async handleContentClick() {
    if (!this.isPermission) {
      // if (!this.isPlanFree) this.showModalPermission();

      // if (this.type === TYPE_NAME_VIDEO) this.showModalCredits();
      // else this.showModalPlanUpdate();

      // return;

      if (this.isPlanFree) this.showModalPlanUpdate();
      else this.showModalPermission();

      return;
    }

    this.saveClickedVideoToStore();

    // if (this.isPlanFree) {
    //   this.handleWithFreePlan();
    //   return;
    // }

    this.redirectTovideo();
  }

  // handleWithFreePlan() {
  //   const { is_credits_video } = this.$store.getters.creditData;

  //   if (is_credits_video) {
  //     this.handleWithFreePlanWhenHasCredit();
  //     return;
  //   }

  //   this.handleWithFreePlanWhenNoCredit();
  // }

  // handleWithFreePlanWhenHasCredit() {
  //   const videoAlreadySeenThisMonth = this.getVideoAlreadySeenThisMonth();

  //   if (!videoAlreadySeenThisMonth) {
  //     this.showModalCreditsWarning();
  //     return;
  //   }

  //   this.redirectTovideo();
  // }

  // handleWithFreePlanWhenNoCredit() {
  //   const videoAlreadySeenThisMonth = this.getVideoAlreadySeenThisMonth();

  //   if (!videoAlreadySeenThisMonth) {
  //     this.showModalCredits();
  //     return;
  //   }

  //   this.redirectTovideo();
  // }

  // getVideoAlreadySeenThisMonth(): boolean {
  //   const { idsVideoWatched } = this.$store.getters;

  //   return idsVideoWatched.length ? idsVideoWatched.includes(this.videoClicked.id) : false;
  // }

  async redirectTovideo() {
    await this.changePath();
    this.emitClickOpenContent();
  }

  saveClickedVideoToStore() {
    this.$store.commit('setVideoClicked', this.data);
  }

  emitClickOpenContent() {
    this.$emit('click-open-content', this.data);
  }

  showModalPlanUpdate() {
    this.$store.commit('setModalPlanUpdate', {
      title: 'Você precisa ser aluno premium para acessar esse conteúdo.',
      description: 'Por menos de R$1,40 por dia, você pode ter acesso ao conteúdo completo de todas as matérias do Ensino Fundamental e Médio.',
    });

    this.setModal.activeModal('modalPlanUpdate');
  }

  showModalPermission() {
    this.setModal.activeModal('modalNotContentPlan');
  }

  // showModalCredits() {
  //   this.setModal.activeModal('modalCredits');
  // }

  // showModalCreditsWarning() {
  //   this.setModal.activeModal('modalCreditsWarning');
  // }

  redirectToMaterial() {
    const path = `${this.data.to}/${this.data.from}/${this.topic.idTopic}/${this.data.id}/${this.data.file.slug}`;

    this.setTrackAmplitude(window.location.href, `${window.location.origin}${path}`);

    this.$router.push({
      path,
    });
  }

  async changePath() {
    if (this.type === TYPE_NAME_VIDEO) {
      this.redirectToVideo();
      return;
    }

    if (this.type === TYPE_NAME_MATERIALS) {
      this.redirectToMaterial();
      return;
    }

    this.saveDataInStore(this.topic, this.data);

    if (this.type === TYPE_NAME_EXERCICE) {
      const isRedirect = await this.redirectExerciseResult();
      if (isRedirect) return;
    }

    const path = `${this.data.to}/${this.data.from}/${this.topic.idTopic}/${this.data.id}/${this.data.file.slug}`;

    this.setTrackAmplitude(window.location.href, `${window.location.origin}${path}`);

    this.$router.push({
      path,
    });
  }

  redirectToVideo() {
    const {
      to,
      from,
      id,
      file,
    } = this.videoClicked;
    const { idTopic } = this.topic;

    const path = `${to}/${from}/${idTopic}/${id}/${file?.slug}`;

    this.setTrackAmplitude(window.location.href, `${window.location.origin}${path}`);

    this.$router.push({
      path,
    });
  }

  saveDataInStore(topic: Record<string, any>, data: Record<string, any>) {
    this.$store.commit('setTopic', topic);
    this.$store.commit('setFile', data.file);
  }

  async redirectExerciseResult() {
    try {
      const response = await this.exerciseService.getExerciseByUser(Number(this.data.id));

      if (response) {
        const path = `${this.data.to}/${this.data.from}/${this.topic.idTopic}/${this.data.id}/${this.data.file.slug}/resultado`;

        this.setTrackAmplitude(window.location.href, `${window.location.origin}${path}`);

        this.$router.push({
          path,
        });

        return true;
      }

      return false;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a resposta do exercício.',
        status: 'error',
      });

      console.error(error);
      return false;
    }
  }

  async setCheckbox(type: string, checked: boolean) {
    this.show = !checked;

    await this.historicService.saveHistoric({
      idobject: this.data.id,
      type: this.type,
      checked: this.show,
    });
  }

  setTrackAmplitude(currentPath: string, toPath: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'SubjectList',
          of: currentPath,
          to: toPath,
        },
      },
    });
  }
}
